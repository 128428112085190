import './App.css';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
function App() {
  return (
    <div className="page">
      <Container >
        <Row xs="1">
          <Col sm="12" md={{ size: 8, offset: 2 }} l={{ size: 6, offset: 3 }}>
            <h1 className="title">The future of local news starts now</h1>
            <img
              className="d-block mx-auto img-fluid w-100 imgpad"
              src="/conservancy-site-logo-2.jpg" alt="Conservancy logo superimposed over a newspaper press"
            />
            <p>
              <strong>
                We believe local news matters, and local news organizations
                should remain in local hands.
              </strong>{" "}
              Our first-of-its-kind local and national partnership means that 24
              local newspapers in Colorado will remain owned by the community
              they serve.
            </p>
            <p>
              The Colorado News Conservancy is a new collaboration by the{" "}
              <a href="https://www.ntln.org" target="_blank" rel="noreferrer">
                National Trust for Local News
              </a>{" "}
              and{" "}
              <a href="http://coloradosun.com" target="_blank" rel="noreferrer">
                The Colorado Sun
              </a>
              . We launched it as a community-centered alternative to national
              consolidation of local and community media. News shouldn’t be
              owned by private equity firms and hedge funds that put profits
              before journalism.
            </p>
            <p>
              We are creating an alternative to national consolidation of local
              news outlets. We believe this can serve as a model for other
              regions. We invite you to learn more about how this points a new
              way forward for the future of local news.
            </p>
            <p>
              Sign up below to stay connected and see how you can support this
              pioneering news initiative.
            </p>

            <Form
              action="https://coloradosun.us18.list-manage.com/subscribe/post?u=fcc98a341cd4bf4bb00f0fe3d&amp;id=f1670bdcb6"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank" rel="noreferrer"
              novalidate
            >
              <FormGroup>
                <Input
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  placeholder="Email"
                />
                <p />
                <Input
                  type="text"
                  name="FNAME"
                  id="mce-FNAME"
                  placeholder="Name"
                />
                <p />
                <Input
                  type="text"
                  name="SUPPORT"
                  id="mce-SUPPORT"
                  placeholder="Why do you support local news?"
                />
                <p />
                <Button
                  color="danger"
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                >
                  Sign Up!
                </Button>
              </FormGroup>
            </Form>
            <hr />
            <img
              className="d-block mx-auto img-fluid w-100"
              src="https://cdn.glitch.com/689ddb4d-f96b-4fdb-9588-22bdae744ec5%2Fntln%2Bsun.png?v=1619817506537" alt="Logos of The National Trust and The Colorado Sun"
            />
            <hr />
            <br />
            <h3>Help Build a Community-Driven Future for Local News</h3>
            <Row xs="1">
              <Col xs={{ size: 8, offset: 2 }} md={{ size: 5, offset: 4 }}>
                <br />
                <Button
                  className="linkbutton"
                  color="danger"
                  size="lg"
                  href="https://checkout.fundjournalism.org/memberform?org_id=colab&campaign=7015G000001IRRfQAO"
                >
                  Support our work
                </Button>
                <br />
              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row xs="1" md="2">
              <Col>
                <h3>Learn more about our partners</h3>

                <p className="subhead">The National Trust for Local News </p>

                <ul>
                  <li>
                    The National Trust for Local News is a new, national
                    nonprofit organization formed to provide the financing, new
                    ownership structures, and expertise needed for established
                    news organizations to become sustainable and deeply grounded
                    in their communities.{" "}
                    <a
                      target="_blank" rel="noreferrer"
                      href="https://www.nationaltrustforlocalnews.org/"
                    >
                      nationaltrustforlocalnews.org
                    </a>
                     
                  </li>
                </ul>

                <p className="subhead">The Colorado Sun</p>

                <ul>
                  <li>
                    The Colorado Sun is a journalist-owned, award-winning
                    digital news outlet that strives to cover all of Colorado,
                    bring understanding to important issues, and contribute to a
                    more vibrant, informed and whole Colorado. The Sun, which
                    was founded in 2018, is a public benefit corporation.{" "}
                    <a target="_blank" rel="noreferrer" href="https://coloradosun.com/">
                      coloradosun.com
                    </a>
                  </li>
                </ul>

                <p className="subhead">American Journalism Project</p>

                <ul>
                  <li>
                     
                    <a target="_blank" rel="noreferrer"  href="http://www.theajp.org/">
                      theajp.org
                    </a>
                     
                  </li>
                </ul>

                <p className="subhead">Colorado Community Media</p>

                <ul>
                  <li>
                    <a
                      target="_blank" rel="noreferrer"
                      href="https://coloradocommunitymedia.com"
                    >
                      coloradocommunitymedia.com
                    </a>{" "}
                  </li>
                </ul>

                <p className="subhead">Colorado Media Project</p>

                <ul>
                  <li>
                    <a
                      target="_blank" rel="noreferrer"
                      href="http://www.coloradomediaproject.com"
                    >
                      coloradomediaproject.com
                    </a>
                     
                  </li>
                </ul>

                <p className="subhead">Colorado News Collaborative (COLab)</p>

                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://colabnews.co">
                      colabnews.co
                    </a>
                     
                  </li>
                </ul>

                <p className="subhead">The Colorado Trust</p>

                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.coloradotrust.org">
                      coloradotrust.org
                    </a>
                     
                  </li>
                </ul>

                <p className="subhead">
                  FJC –A Foundation of Philanthropic Funds 
                </p>

                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://fjc.org/">
                      fjc.org
                    </a>
                  </li>
                </ul>

                <p className="subhead">Gates Family Foundation</p>

                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://gatesfamilyfoundation.org">
                      gatesfamilyfoundation.org
                    </a>
                     
                  </li>
                </ul>

                <p className="subhead">The Google News Initiative</p>

                <ul>
                  <li>
                     
                    <a
                      target="_blank" rel="noreferrer"
                      href="https://newsinitiative.withgoogle.com"
                    >
                      newsinitiative.withgoogle.com
                    </a>
                     
                  </li>
                </ul>

                <p className="subhead">
                  The John S. and James L. Knight Foundation
                </p>

                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://knightfoundation.org">
                      knightfoundation.org
                    </a>
                     
                  </li>
                </ul>

                <p className="subhead">The Lenfest Institute for Journalism</p>

                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.lenfestinstitute.org">
                      lenfestinstitute.org
                    </a>
                  </li>
                </ul>

                <p className="subhead">Public Media Venture Group</p>

                <ul>
                  <li>
                    <a
                      target="_blank" rel="noreferrer"
                      href="https://www.publicmediaventure.com"
                    >
                      publicmediaventure.com
                    </a>
                     
                  </li>
                </ul>
              </Col>
              <Col>
                <h3>Media Resources</h3>
                <ul>
                  <li>
                    <a
                      href="https://lede-admin.coloradosun.com/wp-content/uploads/sites/15/2021/04/24-Community-Newspapers-in-Colorado-Acquired-by-Local-and-National-Funding-Consortium.pdf"
                      target="_blank" rel="noreferrer"
                    >
                      Full press release <em>(May 3, 2021)</em>
                    </a>
                  </li>
                  <li><a href="https://coloradosun.com/2021/05/03/colorado-sun-purchases-colorado-community-media-newspapers/">"Colorado Sun with National Trust purchases 24 Front Range newspapers"</a> — The Colorado Sun (May 3, 2021)</li>
                  <li><a href="https://colabnews.co/a-new-newspaper-ownership-model-emerges-in-colorado/">"A New Newspaper Ownership Model Emerges in Colorado"</a> — Colorado News Collaborative (May 3, 2021)</li>
                  <li><a href="https://www.npr.org/2021/05/03/993059699/in-denver-civic-minded-colorado-sun-acquires-suburban-newspaper-chain">"In Denver, Civic-Minded 'Colorado Sun' Acquires Suburban Newspaper Chain"</a> — NPR (May 3, 2021)</li>
                  <li><a href="https://medium.com/colorado-media-project/why-a-new-chapter-for-24-community-newspapers-matters-for-colorado-and-the-nation-ffcdf57a3a86">"Why a new chapter for 24 community newspapers matters for Colorado — and the nation"</a> — Colorado Media Project (May 3, 2021)</li>
                  <li><a href="https://coloradocommunitymedia.com/stories/we-were-blessed-with-the-opportunities-to-tell-your-stories,376196">"We were blessed with the opportunities to tell your stories
"</a> — Column from former Colorado Community Media co-owner Ann Healey (May 3, 2021)</li>
                  <li><a href="https://coloradocommunitymedia.com/stories/tell-stories-that-matter-colorado-community-media-sold-to-journalism-partnership,376195">"'Tell stories that matter:' Colorado Community Media sold to journalism partnership"</a> — Colorado Community Media (May 3, 2021)</li>

                </ul>
                <h3>Publications under the Colorado News Conservancy
</h3>
                <ul>
                <li>Arvada Press</li>
<li>Brighton Standard Blade</li>
<li>Brighton and Ft. Lupton Advertiser</li>
<li>Canyon Courier</li>
<li>Castle Pines News Press</li>
<li>Castle Rock News Press</li>
<li>Centennial Citizen</li>
<li>Clear Creek Courant</li>
<li>Commerce City Sentinel Express</li>
<li>Denver Herald Dispatch</li>
<li>Douglas County News Press</li>
<li>Elbert County News</li>
<li>Englewood Herald</li>
<li>Fort Lupton Press</li>
<li>Golden Transcript</li>
<li>Highlands Ranch Herald</li>
<li>Jeffco Transcript</li>
<li>Life on Capitol Hill</li>
<li>Littleton Independent</li>
<li>Lone Tree Voice</li>
<li>Northglenn/Thornton Sentinel</li>
<li>Parker Chronicle</li>
<li>South Platte Independent</li>
<li>Washington Park Profile</li>
<li>Westminster Window</li>
<li>285 Hustler</li>
                </ul>



              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <br />
      </Container>
    </div>
  );
}

export default App;
